import clsx from "clsx"
import { useFormik } from "formik"
import { useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import * as Yup from "yup"
import { resetPassword } from "../core/_requests"

const initialValues = {
    otp: '',
    password: '',
    confirm_password: ''
}

const resetPasswordSchema = Yup.object().shape({
    otp: Yup.string()
        .min(4, 'Minimum 4 numbers')
        .max(4, 'Maximum 4 numbers')
        .required('OTP is required'),
    password: Yup.string()
        .min(3, 'Minimum 3 characters')
        .max(50, 'Maximum 50 characters')
        .required('Password is required'),
    confirm_password: Yup.string()
        .min(3, 'Minimum 3 characters')
        .max(50, 'Maximum 50 characters')
        .required('Confirm password is required')
        .oneOf([Yup.ref('password')], "Password and Confirm Password didn't match")
})

export function ResetPassword() {
    const navigate = useNavigate()
    const location = useLocation()
    const email = location?.state?.email
    
    const [loading, setLoading] = useState(false)
    const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)

    const formik = useFormik({
        initialValues,
        validationSchema: resetPasswordSchema,
        onSubmit:  (values, {setStatus, setSubmitting}) => {
            setLoading(true)
            setHasErrors(undefined)
            setTimeout(function() {
                if (email) {
                    resetPassword(email, values.otp, values.password, values.confirm_password)
                    .then((response) => {
                        const responseData = response.data
                        setHasErrors(false)
                        setLoading(false)
                        setStatus(responseData.message)
                        setTimeout(() => {
                            navigate('/auth/login')
                        }, 1000)
                    })
                    .catch((response) => {
                        const responseData = response.response
                        setHasErrors(true)
                        setLoading(false)
                        setSubmitting(false)
                        setStatus(responseData.data.message)
                    })
                } else {
                    setStatus('Sorry, looks like there are some errors detected, please try again.')
                    setLoading(false)
                    setHasErrors(true)
                    setSubmitting(false)
                }
            }, 1000)
        }
    })

    return (
        <form
            className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
            noValidate
            id="password_reset_form"
            onSubmit={formik.handleSubmit}
        >  
            {/* begin::Title */}
            <div className='text-center mb-10'>
                {/* begin::Title */}
                <h1 className='text-dark fw-bolder mb-3'>Set new Password</h1>
                {/* end::Title */}

                {/* begin::Link */}
                <div className='text-gray-500 fw-semibold fs-6'>
                Your new password must be different to previously used passwords.
                </div>
                {/* end::Link */}
            </div>

            {hasErrors === true && (
                <div className='mb-lg-15 alert alert-danger'>
                    <div className='alert-text font-weight-bold'>{formik.status}</div>
                </div>
            )}

            {hasErrors === false && (
                <div className='mb-10 bg-light-success p-8 rounded'>
                    <div className='fw-bold text-success'>{formik.status}</div>
                </div>
            )}

            {/* begin::Form group Password */}
            <div className='fv-row mb-4'>
                <label className='form-label fw-bolder text-dark fs-6'>OTP</label>
                <div className='position-relative mb-3'>
                    <input
                        type='text'
                        placeholder='OTP'
                        autoComplete='off'
                        {...formik.getFieldProps('otp')}
                        className={clsx(
                            'form-control bg-transparent',
                            {
                            'is-invalid': formik.touched.otp && formik.errors.otp,
                            },
                            {
                            'is-valid': formik.touched.otp && !formik.errors.otp,
                            }
                        )}
                    />
                    {formik.touched.otp && formik.errors.otp && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.otp}</span>
                        </div>
                    </div>
                    )}
                </div>
            </div>
            {/* end::Form group */}

            {/* begin::Form group Password */}
            <div className='fv-row mb-4'>
                <label className='form-label fw-bolder text-dark fs-6'>Password</label>
                <div className='position-relative mb-3'>
                    <input
                        type='password'
                        placeholder='Password'
                        autoComplete='off'
                        {...formik.getFieldProps('password')}
                        className={clsx(
                            'form-control bg-transparent',
                            {
                            'is-invalid': formik.touched.password && formik.errors.password,
                            },
                            {
                            'is-valid': formik.touched.password && !formik.errors.password,
                            }
                        )}
                    />
                    {formik.touched.password && formik.errors.password && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.password}</span>
                        </div>
                    </div>
                    )}
                </div>
            </div>
            {/* end::Form group */}
            
            {/* begin::Form group Confirm password */}
            <div className='fv-row mb-8'>
                <label className='form-label fw-bolder text-dark fs-6'>Confirm Password</label>
                <input
                    type='password'
                    placeholder='Confirm Password'
                    autoComplete='off'
                    {...formik.getFieldProps('confirm_password')}
                    className={clsx(
                        'form-control bg-transparent',
                        {
                        'is-invalid': formik.touched.confirm_password && formik.errors.confirm_password,
                        },
                        {
                        'is-valid': formik.touched.confirm_password && !formik.errors.confirm_password,
                        }
                    )}
                />
                {formik.touched.confirm_password && formik.errors.confirm_password && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.confirm_password}</span>
                        </div>
                    </div>
                )}
            </div>
            {/* end::Form group */}
            
            {/* begin::Form group */}
            <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
                <button 
                    type='submit' 
                    id='password_reset_submit' 
                    className='btn btn-primary me-4'
                    disabled={loading}
                >
                    {!loading && (
                        <span className='indicator-label'>Submit</span>
                    )}
                    {loading && (
                        <span className='indicator-label'>
                            Please wait...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                    )}
                </button>
                <button
                    type='button'
                    id='password_reset_form_cancel_button'
                    className='btn btn-light'
                    disabled={formik.isSubmitting || !formik.isValid}
                    onClick={() => navigate('/auth/login')}
                >
                    Cancel
                </button>
            </div>
            {/* end::Form group */}
        </form>
    )
}