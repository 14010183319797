import { useNavigate } from "react-router-dom";
import { useAuth } from "../modules/auth";
import { KTSVG } from "../../_theme/helpers";

export function HeaderUserMenu() {
    const { logout } = useAuth()
    const navigate = useNavigate()

    return (
        <div 
            className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
            data-kt-menu="true"
        >
            <div className='menu-item pe-5'>
                <a onClick={() => navigate('/', {state: {action: 'receive'}})} className='menu-link px-5 gap-4'>
                    <KTSVG path="/media/qr.svg" />
                    <span>Your QR Code</span>
                </a>
            </div>
            <div className='menu-item pe-5'>
                <a onClick={() => navigate('/change-password')} className='menu-link px-5 gap-4'>
                    <KTSVG path="/media/password.svg" />
                    <span>Change Password</span>
                </a>
            </div>
            <div className='menu-item pe-5'>
                <a onClick={logout} className='menu-link px-5 gap-4'>
                    <KTSVG path="/media/logout.svg" />
                    <span>Log Out</span>
                </a>
            </div>
        </div>
    )
}