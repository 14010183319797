import { useFormik } from "formik"
import { useState } from "react"
import { useAuth } from "../core/Auth"
import clsx from "clsx"
import * as Yup from "yup"
import { Link } from "react-router-dom"
import { getUserByToken, login } from "../core/_requests"


const initialValues = {
    email: '',
    password: '',
}

const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Email is required'),
    password: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Password is required'),
})

export function Login() {
    const [loading, setLoading] = useState(false)
    const {saveAuth, setCurrentUser} = useAuth()

    const formik = useFormik({
        initialValues,
        validationSchema: loginSchema,
        onSubmit: async (values, {setStatus, setSubmitting}) => {
            setLoading(true)
            try {
                const {data: auth} = await login(values.email, values.password)
                saveAuth(auth)
                const {data: user} = await getUserByToken(auth.api_token)
                setCurrentUser(user)
            } catch (error) {
                saveAuth(undefined)
                setStatus('The login details are incorrect')
                setSubmitting(false)
                setLoading(false)
            }
        }
    })

    return (
        <form
            className="form w-100"
            noValidate
            id="login_form"
            onSubmit={formik.handleSubmit}
        >
            {/* begin::Heading */}
            <div className='text-center mb-11'>
                <h4 className='text-dark fw-bolder mb-3'>Login to your account</h4>
                <div className='text-gray-500 fw-semibold fs-6'>Use your credentials to access your account.</div>
            </div>
            {/* begin::Heading */}

            {formik.status && (
                <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>{formik.status}</div>
                </div>
            )}

            {/* begin::Form group */}
            <div className='fv-row mb-8'>
                <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
                <input
                    placeholder="Email"
                    {...formik.getFieldProps("email")}
                    className={clsx(
                        'form-control bg-transparent',
                        {'is-invalid': formik.touched.email && formik.errors.email},
                        {
                          'is-valid': formik.touched.email && !formik.errors.email,
                        }
                    )}
                    type="email"
                    name="email"
                    autoComplete="off"
                />
                {formik.touched.email && formik.errors.email && (
                    <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.email}</span>
                    </div>
                )}
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className='fv-row mb-3'>
                <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
                <input
                type='password'
                autoComplete='off'
                placeholder="Password"
                {...formik.getFieldProps('password')}
                className={clsx(
                    'form-control bg-transparent',
                    {
                    'is-invalid': formik.touched.password && formik.errors.password,
                    },
                    {
                    'is-valid': formik.touched.password && !formik.errors.password,
                    }
                )}
                />
                {formik.touched.password && formik.errors.password && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.password}</span>
                        </div>
                    </div>
                )}
            </div>
            {/* end::Form group */}

            {/* begin::Wrapper */}
            <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
                {/* begin::Link */}
                <Link to='/auth/forgot-password' className='link-primary ms-auto'>
                Forgot Password ?
                </Link>
                {/* end::Link */}
            </div>
            {/* end::Wrapper */}

            {/* begin::Action */}
            <div className='d-grid mb-10'>
                <button
                type='submit'
                id='kt_sign_in_submit'
                className='btn btn-primary'
                disabled={formik.isSubmitting || !formik.isValid}
                >
                {!loading && <span className='indicator-label'>Login</span>}
                {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                )}
                </button>
            </div>
            {/* end::Action */}
            
            <div className='text-gray-500 text-center fw-semibold fs-6'>
                Not a Member yet?{' '}
                <Link to='/auth/signup' className='link-primary'>
                Sign up
                </Link>
            </div>
        </form>
    )
}