import { useState } from "react"
import { useAuth } from "../core/Auth"
import { useFormik } from "formik"
import * as Yup from "yup"
import axios from "axios"
import { AUTH_BY_UUID_URL } from "../../../api"
import clsx from "clsx"
import { getUserByToken } from "../core/_requests"
import { getIP, KTIcon } from "../../../../_theme/helpers"
import { useNavigate } from "react-router-dom"
import socket from "../../../../socket"

const initialValues = {
    uuid: ''
}

const authenticationSchema = Yup.object().shape({
    uuid: Yup.string()
     .required('UID is required')
})

export function AuthByUid({authByUid, setAuthByUid}: any) {
    const [loading, setLoading] = useState(false)
    const {saveAuth, setCurrentUser, logout} = useAuth()
	const navigate = useNavigate()

    const formik = useFormik({
        initialValues,
        validationSchema: authenticationSchema,
        onSubmit: async(values, {setStatus, setSubmitting}) => {
            setLoading(true)
            try {
				const ip = await getIP();
                const {data: auth} = await axios.post(AUTH_BY_UUID_URL, {
                    'uuid': values.uuid,
					'ip': ip
                })
				if (auth?.data?.error) {
                    setStatus(auth?.data?.message)
                } else {
                    logout()
					saveAuth(auth)
					const {data: user} = await getUserByToken(auth?.api_token)
					setCurrentUser(user)
                    socket.auth = user
                    socket.connect()
                    console.log(`/dashboard?active=${btoa(unescape(encodeURIComponent(auth?.extra?.conversation_id)))}`)
					navigate(`/dashboard?active=${btoa(unescape(encodeURIComponent(auth?.extra?.conversation_id)))}`)
				}
            } catch (error) {
                saveAuth(undefined)
                setStatus('Something went wrong. try again later!')
                setSubmitting(false)
                setLoading(false)
            }
        }
    });

    return (
        <>
            <div className="modal fade show d-block">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header justify-content-between">
                            <h2 className="fw-bold mb-0">Enter UID</h2>
                            <div className="btn btn-icon btn-sm btn-active-icon-primary" onClick={() => setAuthByUid(false)}>
                                <KTIcon iconName="cross" className="fs-1" />
                            </div>
                        </div>
                        <form id="auth_by_uuid" noValidate onSubmit={formik.handleSubmit}>
                            <div className="modal-body py-lg-10 px-lg-17">
                                {formik.status && (
                                    <div className='mb-lg-15 alert alert-danger'>
                                    <div className='alert-text font-weight-bold'>{formik.status}</div>
                                    </div>
                                )}
                                <div className="fv-row fv-plugins-icon-container">
                                    <label className="required fs-6 fw-semibold mb-2">UID</label>
                                    <input
                                        placeholder="Enter your UID"
                                        {...formik.getFieldProps('uuid')}
                                        className={clsx(
                                            "form-control form-control-solid",
                                            {"is-invalid": formik.touched.uuid && formik.errors.uuid},
                                            {
                                                "is-valid": formik.touched.uuid && !formik.errors.uuid,
                                            }
                                        )}
                                        type="text"
                                        name="uuid"
                                        autoComplete="off"
                                    />
                                    {formik.touched.uuid && formik.errors.uuid && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">{formik.errors.uuid}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="modal-footer border-0">
                                <button
                                    type="submit"
                                    id="kt_auth_by_uuid_submit"
                                    className="btn btn-primary"
                                    disabled={formik.isSubmitting || !formik.isValid}

                                >
                                    {!loading && <span className="indicator-label">Submit</span>}
                                    {loading && (
                                        <span className="indicator-progress" style={{display: 'block'}}>
                                            Please wait...
                                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                        </span>
                                    )}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop fade show"></div>
        </>
    )
}