import { useState } from "react";
import { toAbsoluteUrl } from "../../_theme/helpers";
import * as Yup from "yup";
import { useFormik } from "formik";
import { changePassword } from "../modules/auth/core/_requests";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";

const initialValues = {
    current_password: '',
    new_password: '',
    confirm_new_password: ''
}

const changePasswordschema = Yup.object().shape({
    current_password: Yup.string()
        .required('Current password is required'),
    new_password: Yup.string()
        .required('New password is required')
        .min(3, 'Minimum 3 characters')
        .max(50, 'Maximum 50 characters'),
    confirm_new_password: Yup.string()
        .required('Confirm new password is required')
        .min(3, 'Minimum 3 characters')
        .max(50, 'Maximum 50 characters')
        .oneOf([Yup.ref('new_password')], 'New password and confirm new password does not match')
})

export function ChangePassword() {
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)

    const formik = useFormik({
        initialValues,
        validationSchema: changePasswordschema,
        onSubmit: (values, {setStatus, setSubmitting}) => {
            setLoading(true)
            setHasErrors(undefined)
            setTimeout(() => {
                changePassword(values.current_password, values.new_password, values.confirm_new_password)
                    .then((response) => {
                        const responseData = response.data
                        setHasErrors(false)
                        setLoading(false)
                        setStatus(responseData.message)
                        setTimeout(() => {
                            navigate('/')
                        }, 1000)
                    })
                    .catch((response) => {
                        const responseData = response.response
                        setHasErrors(true)
                        setLoading(false)
                        setSubmitting(false)
                        setStatus(responseData.data.message)
                    })
            }, 1000)
        }
    })

    return (
        <>
            <div className="auth-container">
                <div className="auth-inner">
                    <div className="auth-form">
                        <div className="app-logo text-center mb-4">
                            <img src={toAbsoluteUrl('/media/logos/default.png')} alt="ZupShare" />
                        </div>
                        <form 
                            className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework" 
                            noValidate 
                            id="change_password_form"
                            onSubmit={formik.handleSubmit}
                        >
                            {/* begin::Title */}
                            <div className='text-center mb-10'>
                                {/* begin::Title */}
                                <h1 className='text-dark fw-bolder mb-3'>Change Password</h1>
                                {/* end::Title */}

                                {/* begin::Link */}
                                <div className='text-gray-500 fw-semibold fs-6'>Your new password must be different to previously used passwords.</div>
                                {/* end::Link */}
                            </div>

                            {hasErrors === true && (
                                <div className='mb-lg-15 alert alert-danger'>
                                    <div className='alert-text font-weight-bold'>{formik.status}</div>
                                </div>
                            )}

                            {hasErrors === false && (
                                <div className='mb-10 bg-light-success p-8 rounded'>
                                    <div className='fw-bold text-success'>{formik.status}</div>
                                </div>
                            )}
                            {/* end::Title */}

                            {/* begin::Form group Password */}
                            <div className='fv-row mb-4'>
                                <label className='form-label fw-bolder text-dark fs-6'>Current Password</label>
                                <div className='position-relative mb-3'>
                                    <input
                                        type='password'
                                        placeholder='Current Password'
                                        autoComplete='off'
                                        {...formik.getFieldProps('current_password')}
                                        className={clsx(
                                            'form-control bg-transparent',
                                            {
                                            'is-invalid': formik.touched.current_password && formik.errors.current_password,
                                            },
                                            {
                                            'is-valid': formik.touched.current_password && !formik.errors.current_password,
                                            }
                                        )}
                                    />
                                    {formik.touched.current_password && formik.errors.current_password && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.current_password}</span>
                                        </div>
                                    </div>
                                    )}
                                </div>
                            </div>
                            {/* end::Form group */}

                            {/* begin::Form group Password */}
                            <div className='fv-row mb-4'>
                                <label className='form-label fw-bolder text-dark fs-6'>New Password</label>
                                <div className='position-relative mb-3'>
                                    <input
                                        type='password'
                                        placeholder='New Password'
                                        autoComplete='off'
                                        {...formik.getFieldProps('new_password')}
                                        className={clsx(
                                            'form-control bg-transparent',
                                            {
                                            'is-invalid': formik.touched.new_password && formik.errors.new_password,
                                            },
                                            {
                                            'is-valid': formik.touched.new_password && !formik.errors.new_password,
                                            }
                                        )}
                                    />
                                    {formik.touched.new_password && formik.errors.new_password && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.new_password}</span>
                                        </div>
                                    </div>
                                    )}
                                </div>
                            </div>
                            {/* end::Form group */}

                            {/* begin::Form group Password */}
                            <div className='fv-row mb-4'>
                                <label className='form-label fw-bolder text-dark fs-6'>Confirm New Password</label>
                                <div className='position-relative mb-3'>
                                    <input
                                        type='password'
                                        placeholder='Confirm New Password'
                                        autoComplete='off'
                                        {...formik.getFieldProps('confirm_new_password')}
                                        className={clsx(
                                            'form-control bg-transparent',
                                            {
                                            'is-invalid': formik.touched.confirm_new_password && formik.errors.confirm_new_password,
                                            },
                                            {
                                            'is-valid': formik.touched.confirm_new_password && !formik.errors.confirm_new_password,
                                            }
                                        )}
                                    />
                                    {formik.touched.confirm_new_password && formik.errors.confirm_new_password && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.confirm_new_password}</span>
                                        </div>
                                    </div>
                                    )}
                                </div>
                            </div>
                            {/* end::Form group */}

                            {/* begin::Form group */}
                            <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
                                <button 
                                    type='submit' 
                                    id='change_password_submit' 
                                    className='btn btn-primary me-4'
                                    disabled={loading}
                                >
                                    {!loading && (
                                        <span className='indicator-label'>Submit</span>
                                    )}
                                    {loading && (
                                        <span className='indicator-label'>
                                        Please wait...
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    )}
                                </button>
                                <button
                                    type='button'
                                    id='change_password_form_cancel_button'
                                    className='btn btn-light'
                                    disabled={formik.isSubmitting || !formik.isValid}
                                    onClick={() => navigate('/')}
                                >
                                    Cancel
                                </button>
                            </div>
                            {/* end::Form group */}
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}