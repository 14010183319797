//const API_URL = 'http://localhost/zupshare/api';
const API_URL = 'https://zupshare.com/api';

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`;
export const LOGIN_URL = `${API_URL}/login`;
export const REGISTER_URL = `${API_URL}/register`;
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`;
export const RESET_PASSWORD_URL = `${API_URL}/reset_password`;
export const AUTH_BY_SCAN_URL = (token: any) => `${API_URL}/auth_by_scan/${token}`;
export const AUTH_BY_UUID_URL = `${API_URL}/auth_by_uuid`;
export const GET_USER_HISTORY = `${API_URL}/history`;
export const CHANGE_PASSWORD_URL = `${API_URL}/change_password`;

// Chat Apis
export const GET_CONVERSATIONS_URL = `${API_URL}/conversations`;
export const GET_CONVERSATION_MESSAGES_URL = (conversationId: any) => `${API_URL}/conversations/${conversationId}/messages`;
export const CONVERSATION_MARK_AS_READ_URL = (conversationId: any) => `${API_URL}/conversations/${conversationId}/mark-as-read`;