import React, { useEffect, useLayoutEffect, useRef, useState } from "react"
import socket from "../../../../socket";
import { useAuth } from "../../auth";
import clsx from "clsx";
import { ScrollComponent } from "../../../../_theme/assets/ts/components";
import { KTIcon, QUERIES, toAbsoluteUrl } from "../../../../_theme/helpers";
import { useQuery } from "react-query";
import { getUserMessages } from "../core/_requests";
import { useMessageContext } from "../core/MessageContext";

const ChatBox = () => {
    const {currentUser} = useAuth()
    const { activeConversation, setActiveConversation, messages, setMessages, message, setMessage, files, setFiles, isFileUploading, setIsFileUploading, typingUsers, cursor, setCursor, hasMore, setHasMore, isInitialLoad, setIsInitialLoad } = useMessageContext()
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [selectedUser, setSelectedUser]:any = useState({});
    const lastMessageRef:any = useRef(null);
    const [error, setError] = useState('');
    const [chatHeight, setChatHeight] = useState(lastMessageRef?.current?.scrollHeight);
    const [isSetChatHeight, setIsSetChatHeight] = useState(false);
    const [progress, setProgress] = useState(0);
    //const [hasScrollbar, setHasScrollbar] = useState(false);

    useEffect(() => {
        if (activeConversation?.id) {         
            setSelectedUser(activeConversation?.users.find((user: any) => user.id !== currentUser?.id))
            ScrollComponent.bootstrap()
            
            // Handle message read
            if (activeConversation?.unread_count > 0) {
                socket.emit("messageRead", {conversationId: activeConversation?.id});
            }
        }
    }, [activeConversation]);
    
    // Get conversation messages
    const {
        isFetching,
        refetch,
        data: data,
    } = useQuery(
        `${QUERIES.USERS_MESSAGES}-${activeConversation?.id}`,
        () => {
            if (cursor > 0) {
                return getUserMessages(activeConversation?.id, `cursor=${cursor}`)
            } else {
                return getUserMessages(activeConversation?.id)
            }
        },
        {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false, enabled: !!activeConversation}
    )

    useEffect(() => {
        if (data && data?.messages) {
            setMessages((prevMessages: any) => [
                ...data?.messages,
                ...prevMessages
            ])
            setHasMore(data?.pagination?.has_more);
            setCursor(data?.pagination?.next_cursor);
        }
    }, [data, setMessages])

    /*useEffect(() => {
        if (!activeConversation) return;

        // Listen for "typing" event
        socket.on("typingResponse", ({userId, isTyping}) => {
            setTypingUsers((prev: any) => ({ ...prev, [userId]: isTyping }));
        })

        // Cleanup listener on unmount
        return () => {
            socket.off("typingResponse");
        };
    }, [activeConversation])*/

    const sendMessage = async () => {
        if (!files.length && !message.trim()) {
            setError("Please provide files and a message!");
            return;
        }

        const formData = new FormData();
        files.forEach((file: any, index: any) => {
            formData.append(`files[${index}][name]`, file.name);
            formData.append(`files[${index}][type]`, file.type);
            formData.append(`files[${index}][size]`, String(file.size));
            formData.append(`files[${index}][content]`, file);
        });

        formData.append('message', message);
        formData.append('conversation_id', activeConversation?.id);
        formData.append('user_id', String(currentUser?.id));
        formData.append('recipient_id', selectedUser?.id);

        const dataToSend: Record<string, any> = {};
        formData.forEach((value, key) => {
            dataToSend[key] = value;
        });

        if (files.length > 0) {
            setIsFileUploading(true);
        }

        socket.emit("message", dataToSend);
        socket.on("progress", (progress) => {
            setProgress(progress);
        });
        setMessage("");
        setFiles([]);
        socket.emit("typing", {conversationId: activeConversation?.id, userId: currentUser?.id, isTyping: false});
        setIsInitialLoad(true);
    }

    const onEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.keyCode === 13 && e.shiftKey === false) {
            e.preventDefault()
            sendMessage()
        }
    }

    const handleTyping = () => {
        socket.emit("typing", {conversationId: activeConversation?.id, userId: currentUser?.id, isTyping: true}); // Notify server the user is typing
        setTimeout(() => socket.emit("typing", {conversationId: activeConversation?.id, userId: currentUser?.id, isTyping: false}), 2000); // Stop typing after 2 seconds
    }

    const handleFiles = (uploadFiles: any) => {
        if (files.length < 10) {
            const newFiles = Array.from(uploadFiles).slice(0, (10 - files.length));
            //const limitFiles = Object.fromEntries(Object.entries(uploadFiles).slice(0,(10 - files.length)));
            setFiles((prevFiles: any) => {
                const uniqueFiles = newFiles.filter((newFile: any) =>
                    !prevFiles.some((prevFile: any) => 
                        prevFile.name === newFile.name && prevFile.size === newFile.size
                    )
                );
                return [...prevFiles, ...uniqueFiles];
            });
            if (!isSetChatHeight) {
                setChatHeight(lastMessageRef?.current?.offsetHeight - 70 - 70);
                setIsSetChatHeight(true);
            }
            if (files.length > 10) {
                setError("Can't add more than 10 files at a time!");
            }
        } else {
            setError("Can't add more than 10 files at a time!");
        }

        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    }

    const handleRemoveFile = (key: any) => {
        setFiles(files.filter((file: any, fileKey: any) => fileKey !== key));
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    }

    useEffect(() => {
        if (isSetChatHeight && files.length === 0) {
            setChatHeight(lastMessageRef?.current?.offsetHeight + 70 + 70);
            setIsSetChatHeight(false);
        }
    }, [files])
    
    // Auto-scroll to the latest message
    useEffect(() => {
        if (isInitialLoad) {
            if (lastMessageRef.current) {
                lastMessageRef.current.scrollTop = lastMessageRef.current.scrollHeight;
            }
        }
    }, [messages])

    // Handle scroll event
    const handleScroll =  async () => {
        if (!lastMessageRef.current || isFetching || !hasMore) return;

        const { scrollTop } = lastMessageRef.current;
        
        // If scrolled to the top
        if (scrollTop === 0) {
            setIsInitialLoad(false);
            const scrollHeightBefore = lastMessageRef.current.scrollHeight;
            await refetch();
            setTimeout(() => {
                const scrollHeightAfter = lastMessageRef.current.scrollHeight;
                lastMessageRef.current.scrollTop += scrollHeightAfter - scrollHeightBefore;
            }, 0);
        }
    };

    // Scroll listener
    useEffect(() => {
        const chatContainer = lastMessageRef.current;
        
        if (chatContainer) {
            //const checkForScrollbar = () => {
            //    setHasScrollbar(chatContainer.scrollHeight > chatContainer.clientHeight);
            //}

            //checkForScrollbar()

            chatContainer.addEventListener("scroll", handleScroll);
            //window.addEventListener('resize', checkForScrollbar);

            return () => {
                chatContainer.removeEventListener("scroll", handleScroll);
                //window.removeEventListener('resize', checkForScrollbar);
            };
        }
    }, [activeConversation, refetch, hasMore]);
    //isFetching, refetch, cursor, hasMore

    useEffect(() => {
        if (error.length !== 0) {
            setTimeout(() => {
                setError('');
            }, 5000);
        }
    });

    const chatMessages = messages && messages?.map((data: any, index: any) => {
            const { user_id, message, image, file_url, type } = data
            const self = currentUser?.id
            const state = user_id  === self ? 'info' : 'primary'
            const contentClass = `d-flex justify-content-${
                user_id === self ? 'end' : 'start'
            } ${index === 0 ? 'my-6' : 'mb-6'}`
            
            return (
                <div
                    key={`message${index}`}
                    className={clsx('d-flex', contentClass)}
                >
                    <div
                        className={clsx(
                        'd-flex flex-column align-items',
                        'gap-1',
                        `align-items-${user_id === self ? 'end' : 'start'}`
                        )}
                    >
                        {
                            image?.length > 0 ?
                            <>
                                <div
                                className={clsx(
                                    'p-5 rounded',
                                    `bg-light-${state}`,
                                    'text-dark fw-bold w-100',
                                    `text-${user_id === self ? 'end' : 'start'}`
                                )}
                                >
                                    <a href={file_url} download>
                                        <div className="d-flex align-items-center flex-column gap-2">
                                            {
                                                (type === 'image/jpg' || type === 'image/jpeg' || type === 'image/png')
                                                    ?
                                                <img src={file_url} className="w-50px h-50px" alt="" />
                                                    :
                                                <img src={toAbsoluteUrl('/media/document.png')} alt="" className="w-50px h-50px" />
                                            }
                                            <span className="text-gray-500 fw-bold fs-8 mw-150px file-name">{image}</span>
                                        </div>
                                    </a>
                                </div>
                                {/*<span className="fs-7 fw-bold text-muted">{sent_at}</span>*/}
                            </> :
                            <>
                                <div
                                className={clsx(
                                    'p-5 rounded',
                                    `bg-light-${state}`,
                                    'text-dark fw-bold mw-lg-400px',
                                    `text-${user_id === self ? 'end' : 'start'}`
                                )}
                                dangerouslySetInnerHTML={{__html: message}}
                                ></div>
                                {/*<span className="fs-7 fw-bold text-muted">{sent_at}</span>*/}
                            </>
                        }
                    </div>
                </div>
            )
        });

    return (
        <div id="chat-window" className={`flex-lg-row-fluid ms-lg-6 ms-xl-6 ${activeConversation ? 'enable' : ''}`}>
            {!activeConversation?.id && 
                <div className="d-none d-lg-flex h-100">
                    <div className="d-flex align-items-center flex-column gap-4 m-auto">
                        <span className="symbol symbol-100px">
                            <img src={toAbsoluteUrl('/media/logos/default.png')} alt="App Logo" />
                        </span>
                        <span className="fs-2x fw-medium">Welcome to zupshare</span>
                    </div>
                </div>
            }
            {activeConversation?.id &&
                <div className='card h-100' id='app_chat_messenger'>
                    <div className='card-header px-6 ps-3 ps-lg-6' id='app_chat_messenger_header'>
                        <div className="d-flex align-items-center gap-4">
                            <div className="d-lg-none">
                                <a onClick={() => { setActiveConversation(null); setMessages([]) }}>
                                    <KTIcon iconName="arrow-left" className="fs-3x" />
                                </a>
                            </div>
                            <div className='card-title'>
                                <div className='symbol-group symbol-hover'>
                                    <div className="symbol symbol-circle symbol-45px">
                                        <img src={toAbsoluteUrl('/media/avatars/blank.png')} alt="avatar" />
                                    </div>
                                </div>
                                <div className='d-flex justify-content-center flex-column ms-4'>
                                    <span className='fs-4 fw-bolder text-gray-900 me-1 mb-2 lh-1'>
                                    {selectedUser?.first_name != null && selectedUser?.first_name != "" ? `${selectedUser?.first_name} ${selectedUser?.last_name}` : selectedUser?.uuid}
                                    </span>
                                    <div className='mb-0 lh-1'>
                                        <span className='fs-7 fw-bold text-gray-400'>
                                            {Object.values(typingUsers).some((typingResponse: any) => {
                                                const { conversation_id, user_id, is_typing } = typingResponse;
                                                if (conversation_id === activeConversation?.id && user_id === selectedUser?.id === is_typing) {
                                                    return true;
                                                }
                                                return false;
                                            }) ? 'typing...' : ''}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className='card-body p-0'
                        id='app_chat_messenger_body'
                    >
                        <div
                            className="scroll-y px-6 pe-5"
                            style={{height: `${chatHeight}px`}}
                            data-kt-element='messages'
                            data-kt-scroll='true'
                            data-kt-scroll-activate='{default: true, lg: true}'
                            data-kt-scroll-height='auto'
                            data-kt-scroll-max-height='auto'
                            data-kt-scroll-dependencies='#app_chat_messenger_header, #app_chat_messenger_footer'
                            data-kt-scroll-wrappers='#app-container, #app_chat_messenger_body'
                            data-kt-scroll-offset='0px'
                            ref={lastMessageRef}
                        >
                            {
                                isFetching &&
                                <div className="d-flex align-items-center justify-content-center py-4" data-kt-indicator={'on'}>
                                    <span className="indicator-progress mt-3">
                                        <span className="spinner-border spinner-border-primary align-middle"></span>
                                    </span>
                                </div>
                            }
                            {chatMessages}
                        </div>
                    </div>
                    <div
                        className='card-footer p-0'
                        id={'app_chat_messenger_footer'}
                    >
                            {
                                isFileUploading &&
                                <div className="popover bs-popover-auto fade show file-upload-popover">
                                    <div className="popover-arrow"></div>
                                    <div className="popover-body">
                                        <div className="d-flex align-items-center justify-content-center gap-2">
                                            <span className="fs-5 fw-medium text-white">Files Uploading...</span>
                                            <span className="indicator-info text-dark">
                                                <span className="spinner-border spinner-border-sm spinner-border-white"></span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                error && <span className="fw-bold text-danger w-100 d-flex justify-content-center pt-4">{error}</span>
                            }
                            <div className='py-4 px-6'>
                                <div className={`d-flex flex-column border-radius-24 gap-2 bg-gray-200 w-100 ${isFileUploading ? 'overlay overlay-block' : ''}`}>
                                {
                                        files.length > 0 &&
                                        <div className='p-4 pb-0'>
                                            <div className="conversation-files gap-4 pt-4">
                                                {
                                                    files.map((file: any, key: number) => {
                                                        const {name, type} = file
                                                        return (
                                                            <div key={key} className="d-flex flex-column align-items-center gap-2 position-relative" title={name}>
                                                                <a href={URL.createObjectURL(file)} target="_blank" rel="noreferrer">
                                                                    <img src={(type === 'image/png' || type === 'image/jpg' || type === 'image/jpeg') ? URL.createObjectURL(file) : toAbsoluteUrl('/media/document.png')} alt={name} className="w-25px h-25px w-lg-50px h-lg-50px object-fit-cover rounded" />
                                                                </a>
                                                                <span className="fs-7 fw-medium file-name">{name}</span>
                                                                <div className="remove-file" title="Remove file" onClick={() => handleRemoveFile(key)}>
                                                                    <KTIcon iconName="cross-circle" className="fs-2 text-gray-800 bg-white rounded-circle" />
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    }
                                    <div className={`w-100 position-relative ${isFileUploading ? 'overlay-wrapper' : ''}`}>
                                        <label className='attachment-input'>
                                            <i className='bi bi-paperclip fs-2'></i>
                                            <input type="file" ref={fileInputRef} id="attachment" className='d-none'onChange={(e) => handleFiles(e.target.files)} multiple />
                                        </label>
                                        <textarea
                                            className="form-control form-control-solid form-control-message"
                                            rows={1}
                                            placeholder='Type a message...'
                                            value={message}
                                            onChange={(e) => setMessage(e.target.value)}
                                            onKeyDown={onEnterPress}
                                            onKeyPress={handleTyping}
                                        ></textarea>
                                        <div className="btn-send-message-wrapper">
                                            <div className='btn-send-message bg-primary cursor-pointer' onClick={sendMessage}>
                                                <i className='bi bi-send-fill fs-2 text-white'></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            }
        </div>  
    )
}

export { ChatBox };