import { QrReader } from "react-qr-reader"
import { KTIcon, QUERIES, toAbsoluteUrl } from "../../_theme/helpers"

import { Header } from "../components"
import { AuthByUid } from "../modules/auth/components/AuthByUid"
import { useEffect, useState } from "react"
import { useAuth } from "../modules/auth"
import { useQuery } from "react-query"
import { getUserHistory } from "../modules/chat/core/_requests"
import { useLocation, useNavigate } from "react-router-dom"

export function Home() {
    const { currentUser } = useAuth()
    const [authByScan, setAuthByScan] = useState(false)
    const [authByUid, setAuthByUid] = useState(false)
    const [action, setAction] = useState('send')
    const [historyData, setHistoryData] = useState<any>()
    const [isCopied, setIsCopied] = useState(false)
    const [message, setMessage] = useState('Sorry, you are not logged in!')
    
    const location = useLocation()
    const navigate = useNavigate()
    
    useEffect(() => {
        if (location.state) {
            setAction(location.state.action);
        }
        window.history.replaceState(null, '', window.location.pathname);
    }, [location.state]);

    const {
        isFetching,
        data: data,
    } = useQuery(
        `${QUERIES.USERS_MESSAGES}`,
        () => {
            return getUserHistory();
        },
        {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false, enabled: (currentUser !== undefined && action === 'history')}
    )

    useEffect(() => {
        if (!isFetching) {
            setHistoryData(data);
        }
    }, [isFetching]);
    
    const handleClick = async (action: any) => {
        setAction(action)
    }

    const copyToClipboard = (text: any) => {
        navigator.clipboard.writeText(text)
        setIsCopied(true)
        setTimeout(() => {
            setIsCopied(false)
        }, 1500);
    }
    
    return (
        <>
            <Header />
            <div className="d-flex flex-column flex-root">
                <div className="mh-300px pb-10">
                    <img src={toAbsoluteUrl('/media/template-4.png')} alt="" className="h-100 w-100 object-fit-cover" />
                </div>
                <div className="mx-auto mw-600px w-100 pb-10">
                    <div className="row m-auto">
                        <div className="col-lg-4 col-sm-4 col-4 text-center cursor-pointer" onClick={() => handleClick('send')}>
                            <div className="symbol symbol-circle mb-5">
                                <div className={`symbol-label ${action === 'send' ? 'bg-black' : 'bg-secondary'}`}>
                                    <KTIcon iconName="arrow-up" className={`fs-3x text-white`} />
                                </div>
                            </div>
                            <span className="text-gray-900 fw-bolder d-block fs-4">Send</span>
                        </div>
                        <div className="col-lg-4 col-sm-4 col-4 text-center cursor-pointer" onClick={() => handleClick('receive')}>
                            <div className="symbol symbol-circle mb-5">
                                <div className={`symbol-label ${action === 'receive' ? 'bg-black' : 'bg-secondary'}`}>
                                    <KTIcon iconName="arrow-down" className="fs-3x text-white" />
                                </div>
                            </div>
                            <span className="text-gray-900 fw-bolder d-block fs-4">Receive</span>
                        </div>
                        <div className="col-lg-4 col-sm-4 col-4 text-center cursor-pointer" onClick={() => handleClick('history')}>
                            <div className="symbol symbol-circle mb-5">
                                <div className={`symbol-label ${action === 'history' ? 'bg-black' : 'bg-secondary'}`}>
                                    <KTIcon iconName="message-notif" className="fs-3x text-white" />
                                </div>
                            </div>
                            <span className="text-gray-900 fw-bolder d-block fs-4">History</span>
                        </div>
                    </div>
                </div>
                <div className={`mx-auto mw-600px w-100 pb-10 ${action === 'send' ? 'pt-15' : ''}`}>
                    {
                        action === 'send' &&
                        <div className="row m-auto">
                            <div className="col-lg-6 col-sm-6 col-6 text-center">
                                <span className="cursor-pointer" onClick={() => setAuthByScan(true)}>
                                    <img src={toAbsoluteUrl('/media/qr.gif')} alt="" className="mb-5 mh-70px" />
                                    <span className="text-gray-900 fw-bolder d-block fs-4 mb-2">Scan QR</span>
                                </span>
                            </div>
                            <div className="col-lg-6 col-sm-6 col-6 text-center">
                                <span className="cursor-pointer" onClick={() => setAuthByUid(true)}>
                                    <img src={toAbsoluteUrl('/media/enter-uid.svg')} alt="" className="mb-5 mh-70px" />
                                    <span className="text-gray-900 fw-bolder d-block fs-4 mb-2">Enter UID</span>
                                </span>
                            </div>
                            {authByScan &&
                                <>
                                    <div className="modal fade show d-block">
                                        <div className="modal-dialog modal-dialog-centered">
                                            <div className="modal-content">
                                                <div className="position-absolute top-0 end-0 my-3 mx-3 z-index-3">
                                                    <div className="btn btn-icon btn-active-icon-primary" onClick={() => setAuthByScan(false)}>
                                                        <KTIcon iconName="cross" className="fs-2x" />
                                                    </div>
                                                </div>
                                                <QrReader
                                                    constraints={{facingMode: 'environment'}}
                                                    onResult={(result: any, error: any) => {
                                                        if (!!result) {
                                                            window.location.href = result?.text;
                                                        }

                                                        if (!!error) {
                                                            console.info(error);
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-backdrop fade show" onClick={() => setAuthByScan(false)}></div>
                                </>
                            }
                        </div>
                    }
                    {
                        action === 'receive' &&
                        <>
                            {
                                (currentUser) ?
                                <>
                                    <div className="d-flex flex-column align-items-center gap-2">
                                        <img src={currentUser?.qr_image} alt="" className="mh-500px mw-500px" />
                                        <span className="fw-bolder fs-4">UID - {currentUser?.uuid}</span>
                                        <span className="d-flex align-items-center gap-2">
                                            <span className="fs-5">Copy UID</span>
                                            <span className="lh-1" onClick={() => copyToClipboard(currentUser?.uuid)}>
                                                <KTIcon iconName="copy" className="fs-2 cursor-pointer" />
                                            </span>
                                        </span>
                                        {
                                            isCopied &&
                                            <span className="text-success fw-bold fs-4">UID copied successfully</span>
                                        }
                                    </div>
                                </> : 
                                <>
                                    <div className="text-center">
                                        <div className="text-danger fw-bolder fs-4">{message}</div>
                                    </div>
                                </>
                            }
                        </>
                    }
                    {
                        action === 'history' &&
                        <>
                            <div className="px-5">
                                {isFetching &&
                                <div className="d-flex align-items-center justify-content-center" data-kt-indicator={'on'}>
                                    <span className="indicator-progress mt-3">
                                        <span className="spinner-border spinner-border-primary align-middle"></span>
                                    </span>
                                </div>}
                                {
                                    (currentUser && historyData?.length > 0) ?
                                    <>
                                        <div className="d-flex flex-column justify-content-center gap-6">
                                            {
                                                historyData?.map((data: any, key: any) => {
                                                    const {id, users, latest_message, unread_count } = data;
                                                    const conversationsUsersExcludeCurrentUser = users.filter((user: any) => user.id !== currentUser?.id);
                                                    return (
                                                        <div className={`d-flex align-items-center gap-4 cursor-pointer ${(key + 1) !== historyData?.length ? 'mb-10' : ''}`} key={key} onClick={() => navigate(`dashboard?active=${btoa(unescape(encodeURIComponent(id)))}`)}>
                                                            <div className="symbol symbol-circle symbol-50px">
                                                                <img src={toAbsoluteUrl('/media/avatars/blank.png')} alt="avatar" />
                                                            </div>
                                                            <div className="d-flex flex-column gap-1 flex-grow-1">
                                                                <span className="text-gray-800 fs-4 fw-bolder">
                                                                    {
                                                                        conversationsUsersExcludeCurrentUser.map((conversationUsers: any) => {
                                                                            if (conversationUsers?.first_name != null && conversationUsers?.first_name != "")
                                                                                return conversationUsers?.first_name + ' ' + conversationUsers?.last_name
                                                                            else
                                                                                return conversationUsers?.uuid
                                                                        })
                                                                    }
                                                                </span>
                                                                <span className="text-gray-700 fs-6 fw-medium recent-message">{latest_message?.message || latest_message?.image || ""}</span>
                                                            </div>
                                                            <div className="d-flex flex-column align-items-center gap-1">
                                                                <div className="text-gray-700 fs-6 fw-medium">{latest_message?.formatted_created_at}</div>
                                                                {
                                                                    unread_count > 0 &&
                                                                    <span className="badge badge-success rounded-circle">
                                                                        <span className='text-light fs-8'>{unread_count}</span>
                                                                    </span>
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        <div className="d-flex align-items-center justify-content-center mt-10">
                                            <div onClick={() => navigate('dashboard')} className="btn btn-sm btn-light-primary fs-6">View All</div>
                                        </div>
                                    </> :
                                    <>
                                        {!isFetching &&
                                            <div className="text-center">
                                                <div className="text-danger fw-bolder fs-4">{currentUser ? 'No recent chat' : message}</div>
                                            </div>
                                        }
                                    </>
                                }
                            </div>
                        </>
                    }
                </div>
            </div>
            {authByUid && <AuthByUid authByUid={authByUid} setAuthByUid={setAuthByUid} />}
        </>
    )
}