import axios from "axios"
import { useQuery } from "react-query"
import { AUTH_BY_SCAN_URL } from "../../../api";
import { getIP, toAbsoluteUrl } from "../../../../_theme/helpers";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../core/Auth";
import { getUserByToken } from "../core/_requests";
import socket from "../../../../socket";
import { log } from "console";

export function AuthByScan() {
	const [errorMessage, setErrorMessage] = useState("");
    const {saveAuth, setCurrentUser, logout} = useAuth();
    const { token } = useParams();
    const navigate = useNavigate();
	
    const {
        isLoading,
        data: auth,
    } = useQuery('authScan', async () => {
        const ip = await getIP();
        const response = await axios.post(AUTH_BY_SCAN_URL(token), {
            'ip': ip
        });
        if (response?.data?.error) {
            setErrorMessage(response?.data?.message || "An unexpected error occurred.")
            return false;
        }
        return response?.data;
    },
	{
        cacheTime: 0, 
        keepPreviousData: true, 
        refetchOnWindowFocus: false, 
        async onSuccess(data) {
            if (data) {
                logout()
                saveAuth(data)
                const {data: user} = await getUserByToken(data?.api_token)
                setCurrentUser(user)
                socket.auth = user
                socket.connect()
                navigate(`/dashboard?active=${btoa(unescape(encodeURIComponent(data?.extra?.conversation_id)))}`)
            }
        },
        onError() {
            setErrorMessage("An unexpected error occurred.")
        }
    })

    return (
        <>
            {isLoading &&
                <div className="d-flex align-items-center justify-content-center h-100">
                    <div className="d-flex flex-column align-items-center justify-content-center">
                        <div className="d-flex align-items-center justify-content-center text-primary mb-3" data-kt-indicator="on">
                            <span className="indicator-progress mt-3">
                                <span className="spinner-border align-middle"></span>
                            </span>
                        </div>
                        <h2 className="fw-bold fs-5 text-gray-500">Validating request</h2>
                    </div>
                </div>
            }

            {(!isLoading && auth) &&
                <div className="d-flex align-items-center justify-content-center h-100">
                    <div className="d-flex flex-column align-items-center justify-content-center">
                        <div className="d-flex align-items-center justify-content-center text-primary mb-3" data-kt-indicator="on">
                            <span className="indicator-progress mt-3">
                                <span className="spinner-border align-middle"></span>
                            </span>
                        </div>
                        <h2 className="fw-bold fs-5 text-gray-500">Loading your data...</h2>
                    </div>
                </div>
            }

            {(!isLoading && errorMessage) &&
                <div className="d-flex align-items-center justify-content-center h-100">
                    <div className="d-flex flex-column align-items-center justify-content-center">
                        <div className="mb-11">
                            <img src={toAbsoluteUrl('/media/oc-error.svg')} alt="error" className="mw-lg-400px mw-md-300px mw-200px" />
                        </div>
                        <h2 className="fw-bold fs-4 text-danger mb-6">{errorMessage}</h2>
                        <a href="/" className="btn btn-sm btn-primary">Return to Home</a>
                    </div>
                </div>
            }
        </>
    )
}