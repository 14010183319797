import { useMessageContext } from "../core/MessageContext"
import { KTIcon } from "../../../../_theme/helpers"
import { useAuth } from "../../auth"
import { useNavigate } from "react-router-dom"

const Search = () => {
    const { logout } = useAuth()
    const { searchTerm, setSearchTerm } = useMessageContext()
    const navigate = useNavigate()

    return (
        <div className='card-header pt-7 px-3 px-lg-9' id='kt_chat_contacts_header'>
            <div className="d-flex align-items-center flex-grow-1 gap-4">
                <a className="cursor-pointer" onClick={() => navigate('/')}>
                    <KTIcon iconName="arrow-left" className="fs-lg-2x fs-3x" />
                </a>
                <form className='w-100 position-relative' autoComplete='off'>
                    <KTIcon
                        iconName='magnifier'
                        className='fs-2 text-lg-1 text-gray-500 position-absolute top-50 ms-5 translate-middle-y'
                    />

                    <input
                        type='text'
                        className='form-control form-control-solid px-15'
                        name='search'
                        placeholder='Search by name or uid...'
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </form>
            </div>
        </div>
    )
}

export { Search }