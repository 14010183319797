import axios from "axios"
import { AuthModel, UserModel } from "./_models"

import { CHANGE_PASSWORD_URL, GET_USER_BY_ACCESSTOKEN_URL, LOGIN_URL, REGISTER_URL, REQUEST_PASSWORD_URL, RESET_PASSWORD_URL } from "../../../api"

// Server should return AuthModel
export function login(email: string, password: string) {
    return axios.post<AuthModel>(LOGIN_URL, {
        email,
        password
    })
}

// Server should return AuthModel
export function register(
    firstname: string,
    lastname: string,
    email: string,
    password: string,
    retype_password: string
) {
    return axios.post(REGISTER_URL, {
        first_name: firstname,
        last_name: lastname,
        email,
        password,
        retype_password
    })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
    return axios.post(REQUEST_PASSWORD_URL, {
        email
    })
}

export function resetPassword(email: string, otp: string, password: string, confirm_password: string) {
    return axios.post(RESET_PASSWORD_URL, {
        email,
        otp,
        password,
        confirm_password
    })
}

export function changePassword(current_password: string, new_password: string, confirm_new_password: string) {
    return axios.post(CHANGE_PASSWORD_URL, {
        current_password: current_password,
        new_password: new_password,
        confirm_new_password: confirm_new_password
    })
}

export function getUserByToken(token: string) {
    return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
        api_token: token
    })
}